import { PermissionCheckerService } from "@abp/auth/permission-checker.service";
import { AppSessionService } from "@shared/common/session/app-session.service";
import { Injectable } from "@angular/core";
import { AppMenu } from "./app-menu";
import { AppMenuItem } from "./app-menu-item";

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {}

    getMenu(): AppMenu {
        return new AppMenu("MainMenu", "MainMenu", [
            new AppMenuItem(
                "DASHBOARD",
                "Pages.Administration.Host.Dashboard",
                "flaticon-line-graph",
                "/app/admin/hostDashboard"
            ),
            new AppMenuItem(
                "DASHBOARD",
                "Pages.Tenant.Dashboard",
                "flaticon-line-graph",
                "/app/main/dashboard"
            ),
            new AppMenuItem(
                "Tenants",
                "Pages.Tenants",
                "flaticon-list-3",
                "/app/admin/tenants"
            ),
            new AppMenuItem(
                "Editions",
                "Pages.Editions",
                "flaticon-app",
                "/app/admin/editions"
            ),

            new AppMenuItem(
                "SELF SERVICE",
                "Pages.SelfService",
                "flaticon-book",
                "",
                [
                    new AppMenuItem(
                        "My Attendance",
                        "Pages.ViewTodayPunches",
                        "-",
                        "/app/main/viewpunches/viewpunches"
                    ),

                    // new AppMenuItem('Add Manual Punch', 'Pages.AttandanceStatus', '-', '/app/main/AddPunch/Attandances'),
                    // new AppMenuItem('Advance Details', 'Pages.ManualPunch', '-', '/app/main/AdvanceView/AdvanceViewDetails'),
                    //new AppMenuItem('View Attendance', 'Pages.Planner', '-', '/app/main/userattadnacedaily/userattadnacedaily'),
                    //shikha code
                    //new AppMenuItem('View Today Punches', 'Pages.ViewTodayPunches', '-', '/app/main/viewpunches/viewpunches'),
                    //-shikha code

                    // new AppMenuItem('Approvals', 'Pages.RequestForApprovals', '-', '/app/main/requestForApproval/requestForApprovals'),

                    new AppMenuItem(
                        "Leave Request",
                        "Pages.LeaveRequestses",
                        "-",
                        "/app/main/leaveRequests/leaveRequestses"
                    ),
                    new AppMenuItem(
                        "Attendance Correction Request",
                        "Pages.AttendanceCorrections",
                        "-",
                        "/app/main/attendanceCorrectionRequest/attendanceCorrections"
                    ),
                    new AppMenuItem(
                        "Advance Request",
                        "Pages.AdvanceRequests",
                        "-",
                        "/app/main/advanceRequest/advanceRequests"
                    ),

                    // new AppMenuItem('Request', 'Pages.Request', '-', '', [
                    new AppMenuItem(
                        "On Duty",
                        "Pages.OnDuty",
                        "-",
                        "/app/main/onDutyRequest/onDuty"
                    ),
                    new AppMenuItem(
                        "Employee Data Capture",
                        "Pages.KPIDataCaptureForEmployee",
                        "-",
                        "/app/main/datacapturingForEmployee/datacaptureForEmployee"
                    ),
                    new AppMenuItem(
                        "Performance Review",
                        "Pages.EmployeeSelfReview",
                        "-",
                        "/app/main/selfReview/selfReview"
                    ),

                    //     new AppMenuItem('Leave', 'Pages.LeaveRequestses', '-', '/app/main/leaveRequests/leaveRequestses'),
                    // new AppMenuItem('HOLIDAY', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    // new AppMenuItem('ENCASHMENT', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    // new AppMenuItem('SHORT LEAVE', 'Pages.Countries', 'flaticon-up-arrow', ''),

                    // new AppMenuItem('Attendance', 'Pages.Attendances', '-', '', [
                    //     new AppMenuItem('Attendance Correction', 'Pages.AttendanceCorrections', '-', '/app/main/attendanceCorrectionRequest/attendanceCorrections'),
                    //     new AppMenuItem('On Duty', 'Pages.OnDuty', '-', '/app/main/onDutyRequest/onDuty'),
                    // ]),

                    // new AppMenuItem('Reimbursement', 'Pages.Reimbursement', '-', ''),

                    // new AppMenuItem('EXPENSE', 'Pages.Countries', 'flaticon-up-arrow', '', [
                    //     new AppMenuItem('EXPENSE', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    //     new AppMenuItem('EXPENSE REPORT', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    // ]),

                    // new AppMenuItem('Advance', 'Pages.AdvanceRequests', '-', '/app/main/advanceRequest/advanceRequests'),

                    // new AppMenuItem('ASSET', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    // new AppMenuItem('TRAVEL', 'Pages.Countries', 'flaticon-up-arrow', '', [
                    //     new AppMenuItem('TRAVEL PLAN', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    //     new AppMenuItem('TRAVEL EXPENSE', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    // ]),
                    // ]),
                    // new AppMenuItem('Investment Declaration', 'Pages.InvestmentDeclaration', '-', ''),
                    new AppMenuItem(
                        "Update Personal Details",
                        "Pages.SelfServiceUpdatePersionalDetails",
                        "-",
                        "/app/main/updatepersonaldetails/updatepersonaldetails"
                    ),
                ]
            ),
            new AppMenuItem(
                "FINANCE",
                "Pages.SelfService",
                "flaticon-book",
                "",
                [
                    new AppMenuItem(
                        "Investment Declaration",
                        "Pages.InvestmentDeclaration",
                        "-",
                        "/app/main/InvestmentDeclaration/Declaration"
                    ),
                    new AppMenuItem(
                        "Salary Slip",
                        "Pages.Reports.SalarySlip",
                        "-",
                        "/app/main/report/salaryslip"
                    ),
                    new AppMenuItem(
                        "Yearly Consolidated Salary slip",
                        "Pages.Reports.SalaryRegisterPDF",
                        "-",
                        "/app/main/report/salaryregister"
                    ),
                    // new AppMenuItem('Expense Type', 'Pages.ExpenseType', '-', '/app/main/expensetype/expensetype'),
                    // new AppMenuItem('Expense Policy', 'Pages.ExpensePolicy', '-', '/app/main/mstPolicyExpense/mstPolicyExpense'),
                    new AppMenuItem(
                        "Expense",
                        "Pages.TrnExpensesClaim",
                        "-",
                        "/app/main/trnExpensesClaims/trnExpensesClaim"
                    ),
                    //new AppMenuItem('Reason Module' ,'Pages.ReasonFNF', '-','/app/main/reasonfnf/reasonfnfs'),
                    //new AppMenuItem('Full and Final', 'Pages.TrnExpensesClaim', '-', '/app/main/fullnfinal/fullnfinal'),
                    new AppMenuItem(
                        "Full and Final",
                        "Pages.SeparationForm",
                        "-",
                        "/app/main/fullnfinalModule/fullnfinalModuless"
                    ),
                ]
            ),

            new AppMenuItem(
                "PERFORMANCE",
                "Pages.Performance",
                "flaticon-book",
                "",
                [
                    new AppMenuItem(
                        "KRAs",
                        "Pages.KRA",
                        "-",
                        "/app/main/kra/kra"
                    ),
                    new AppMenuItem(
                        "KPI Policy",
                        "Pages.KPIPolicy",
                        "-",
                        "/app/main/kpipolicy/masterkpipolicy/masterkpipolicy"
                    ),
                    new AppMenuItem(
                        "KPI Policy Assignment",
                        "Pages.KPIPolicyAssignment",
                        "-",
                        "/app/main/kpipolicyAssignment/kpipolicyAssignment"
                    ),
                    new AppMenuItem(
                        "Data Capture",
                        "Pages.KPIDataCapture",
                        "-",
                        "/app/main/datacapturing/datacapture"
                    ),
                    new AppMenuItem(
                        "Manager Review",
                        "Pages.ManagerReview",
                        "-",
                        "/app/main/managerReview/managerReview"
                    ),
                ]
            ),

            new AppMenuItem(
                "ORGANIZATION",
                "Pages.WorkForce",
                "flaticon-user",
                "",
                [
                    new AppMenuItem(
                        "Organization Chart",
                        "Pages.WorkForce",
                        "-",
                        "",
                        [
                            new AppMenuItem(
                                "Branch",
                                "Pages.Locationses",
                                "-",
                                "/app/main/location/locationses"
                            ),
                            new AppMenuItem(
                                "Headquarter",
                                "Pages.Headquarter",
                                "-",
                                "/app/main/headquarter/headquarter"
                            ),
                            new AppMenuItem(
                                "Division",
                                "Pages.Divisions",
                                "-",
                                "/app/main/division/divisions"
                            ),

                            new AppMenuItem(
                                "Department",
                                "Pages.Departmentses",
                                "-",
                                "/app/main/depatrment/departmentses"
                            ),
                            new AppMenuItem(
                                "Sub Departments",
                                "Pages.SubDepartments",
                                "-",
                                "/app/main/departMent/subDepartments"
                            ),

                            new AppMenuItem(
                                "Designation",
                                "Pages.Designations",
                                "-",
                                "/app/main/designation/designations"
                            ),
                        ]
                    ),

                    // new AppMenuItem('Location', '', '-', '', [
                    //     new AppMenuItem('Location', 'Pages.Locationses', '-', '/app/main/location/locationses'),

                // ]),
                // new AppMenuItem('MANAGE EMPLOYEE', '', 'flaticon-up-arrow', '', [
                new AppMenuItem('Increment', 'Pages.Increment', '-', '/app/main/incrementmaster/incrementmaster'),
                
                new AppMenuItem('Users Location Movement', 'Pages.Currentlocationmapping', '-', '/app/main/currentlocationmapping'),
                new AppMenuItem('Visit Summaries', 'Pages.GetEmployeeVisitDetail', '-','/app/main/visitsummaries/visitsummaries'),
                new AppMenuItem('Manage Employee', 'Pages.Employees', '-', '/app/main/employee/employees'),
                new AppMenuItem('Employee Attendance Mode', 'Pages.EmployeeAttendanceMode', '-', '/app/main/employeeAttendanceMode/employeeAttendanceMode'),
                // new AppMenuItem('CONFIRMATION', 'Pages.Countries', 'flaticon-up-arrow', ''),
                // new AppMenuItem('SEPARATION', 'Pages.Countries', 'flaticon-up-arrow', ''),
                // new AppMenuItem('TRANSFER', 'Pages.Countries', 'flaticon-up-arrow', ''),
                new AppMenuItem('Customer Mapping', 'Pages.CustomerMapping', '-', '/app/main/customermaping/customermaping'),
                new AppMenuItem('Contractor', 'Pages.Contractor', '-', '/app/main/employee/contratoremployee'),
                new AppMenuItem('ASSET', 'Pages.Asset', '-', '', [
                    new AppMenuItem('Asset Type', 'Pages.AssetType', '-', '/app/main/asset-master'),
                    new AppMenuItem('Asset', 'Pages.AssetMaster', '-', '/app/main/Asset'),
                    new AppMenuItem('Assigned', 'Pages.Assigned', '-', '/app/main/AssignAsset'),
                ]),
                 
            // ]),

                    // new AppMenuItem('ASSIGN SALARY STRUCTURE', '', 'flaticon-up-arrow', '', [
                    //     new AppMenuItem('TO DO', 'Pages.Countries', 'flaticon-up-arrow', ''),

                    // new AppMenuItem('Increment', 'Pages.Increment', '-', ''),
                    // ]),
                    // new AppMenuItem('Settlement', 'Pages.Settlement', '-', ''),

                    // new AppMenuItem('Employee Policy', '', '-', '', [
                    //     new AppMenuItem('Confirmation', '', '-', '', [
                    //         new AppMenuItem('Policy', 'Pages.EmployePolicy', '-', ''),
                    //     ]),
                    //     new AppMenuItem('Separation', '', '-', '', [
                    //         new AppMenuItem('Exit Questionnaire', 'Pages.ExitQuestionnaire', '-', ''),
                    //         new AppMenuItem('Clearance Check List', 'Pages.ClearanceCheckList', '-', ''),
                    //     ]),
                    //     new AppMenuItem('Setting', 'Pages.Settings', '-', ''),
                    //     new AppMenuItem('Document', 'Pages.Document', '-', ''),
                    // ]),
                    // new AppMenuItem('CHECKLIST', '', 'flaticon-up-arrow', '', [
                    //     new AppMenuItem('LETTER', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    // ]),
                ]
            ),

            new AppMenuItem(
                "TIME OFFICE",
                "Pages.TimeOffice",
                "flaticon-calendar",
                "",
                [
                    //new AppMenuItem('Add Manual Punch', 'Pages.ManualPunch', '-', '/app/main/AddPunch/Attandances'),
                    new AppMenuItem(
                        "Punches",
                        "Pages.PunchSummary",
                        "-",
                        "/app/main/punchsummary/punchsummary"
                    ),
                    new AppMenuItem(
                        "Attendance",
                        "Pages.AttendanceDetail",
                        "-",
                        "/app/main/attandancedashord/attandancedashord"
                    ),
                    new AppMenuItem(
                        "Assign Shift",
                        "Pages.AssignShift",
                        "-",
                        "/app/main/assingshift/shiftDetails"
                    ),
                    new AppMenuItem(
                        "Leave Application",
                        "Pages.ManualPunch",
                        "-",
                        "/app/main/manualeave/manualeave"
                    ),
                    //new AppMenuItem('ATTENDANCE', '', '-', '', [
                    //new AppMenuItem('ALL REQUEST', 'Pages.Countries', '-', ''),
                    // new AppMenuItem('ATTENDENCE DETAILS', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    //new AppMenuItem('ATTENDENCE DETAILS', 'Pages.AttendanceDetail', '-', '/app/main/attandancedashord/attandancedashord'),
                    // new AppMenuItem('ATTENDENCE PROCESS', 'Pages.Countries', '-', ''),
                    //new AppMenuItem('SALARY PROCESSED', 'Pages.Countries', '-', ''),
                    //new AppMenuItem('POLICY NOT MAPPED', 'Pages.Countries', '-', ''),
                    //]),

                    new AppMenuItem(
                        "Import Monthly Attendance",
                        "Pages.MonthWiseportattandnaces",
                        "-",
                        "/app/main/attendance/monthWiseportattandnaces"
                    ),
                    // new AppMenuItem('Policy Not Mapped', 'Pages.PolicyNotMapped', '-', ''),
                    // new AppMenuItem('Manage Shift', 'Pages.ManageShift', '-', ''),
                    new AppMenuItem(
                        "Leave Balance",
                        "Pages.LeaveBlanceses",
                        "-",
                        "/app/main/leaveRule/leaveBlanceses"
                    ),
                    new AppMenuItem("Tool", "", "-", "", [
                        // new AppMenuItem(
                        //     "Update Attendance Status",
                        //     "Pages.AttandanceStatus",
                        //     "-",
                        //     "/app/main/UpdateAttendance/Attandances"
                        // ),
                        // new AppMenuItem(
                        //     "Attendance Process",
                        //     "Pages.AttandanceProcess",
                        //     "-",
                        //     "/app/main/ProcessAttendance/Punches"
                        // ),
                        new AppMenuItem(
                            "Reprocess Punches",
                            "Pages.PunchesRepost",
                            "-",
                            "/app/main/punchreposttool/punchreposttool"
                        ),
                    ]),
                    new AppMenuItem("Time Office Policy", "", "-", "", [
                        new AppMenuItem("Attendance", "", "-", "", [
                            new AppMenuItem(
                                "Create Shift",
                                "Pages.Shifts",
                                "-",
                                "/app/main/shift/shifts"
                            ),
                            new AppMenuItem(
                                "Attendance Rule",
                                "Pages.AttendanceRules",
                                "-",
                                "/app/main/attendanceRule/attendanceRules"
                            ),
                        ]),

                        new AppMenuItem(
                            "Leave Type",
                            "Pages.LeaveTypes",
                            "-",
                            "/app/main/leaveTypes/leaveTypes"
                        ),
                        // new AppMenuItem('Leave Rule', 'Pages.LeaveRules', '-', '/app/main/leaveRule/leaveRules'),

                        new AppMenuItem(
                            "Holiday Policy",
                            "Pages.HoldiayPolicies",
                            "-",
                            "/app/main/holidayPolicy/holdiayPolicies"
                        ),
                        new AppMenuItem(
                            "List of Holidays",
                            "Pages.Holidaies",
                            "-",
                            "/app/main/holiday/holidaies"
                        ),

                        //new AppMenuItem('Check In', 'Pages.Countries', '-', ''),
                        // new AppMenuItem('SETTING', 'Pages.Countries', 'flaticon-up-arrow', ''),
                        //new AppMenuItem('On Duty', 'Pages.Countries', '-', ''),
                    ]),
                ]
            ),

            //for timesheet module
            new AppMenuItem(
                "TIME SHEET",
                "Pages.TimeSheet",
                "flaticon-calendar",
                "",
                [
                    new AppMenuItem(
                        "Activity",
                        "Pages.Activity",
                        "-",
                        "/app/main/time-sheet/activity"
                    ),
                    new AppMenuItem(
                        "Projects",
                        "Pages.Project",
                        "-",
                        "/app/main/time-sheet/projects"
                    ),
                    new AppMenuItem(
                        "Tasks",
                        "Pages.Tasks",
                        "-",
                        "/app/main/time-sheet/tasks"
                    ),
                    new AppMenuItem(
                        "Task Types",
                        "Pages.TaskTypes",
                        "-",
                        "/app/main/time-sheet/task-types"
                    ),
                    new AppMenuItem(
                        "Customers",
                        "Pages.Customers",
                        "-",
                        "/app/main/time-sheet/customers"
                    ),
                ]
            ),
           

            new AppMenuItem("PAYROLL", "Pages.PayRoll", "flaticon-home", "", [
                // new AppMenuItem('Appear (Salary Processing)', '', '-', '', [
                //     new AppMenuItem('Not Proceesed', 'Pages.Countries', '-', ''),
                //     new AppMenuItem('Proceesed', 'Pages.Countries', '-', ''),
                // ]),
                new AppMenuItem(
                    "Manual Advance",
                    "Pages.AdvanceRequests",
                    "-",
                    "/app/main/advanceRequestManual/advanceRequestsManual"
                ),
                new AppMenuItem(
                    "Process Salary",
                    "Pages.Salaries",
                    "-",
                    "/app/main/salary/salaryprocess"
                ),
                new AppMenuItem('Employees Salary Slip', 'Pages.GetAllEmployeesSalary', '-', '/app/main/employees/salary'),

                new AppMenuItem(
                    "Salary Book",
                    "Pages.Reports.EmployeeSalaryRegister",
                    "-",
                    "/app/main/salarybook/salarybooks"
                ),
                new AppMenuItem(
                    "Employee VPF",
                    "Pages.EmployeeVPF",
                    "-",
                    "/app/main/employee/employeevpf"
                ),
                // new AppMenuItem('Over Time', 'Pages.OverTime', '-', '', [
                //     new AppMenuItem('Not Proceesed', 'Pages.OverTimeNotProcessed', '-', ''),
                //     new AppMenuItem('Proceesed', 'Pages.OverTimeProcessed', '-', ''),
                // ]),
                // new AppMenuItem('Manage Advance', 'Pages.ManageAdvance', '-', '', [
                //     new AppMenuItem('Not Proceesed', 'Pages.AdvanceNotProcessed', '-', ''),
                //     new AppMenuItem('Proceesed', 'Pages.AdvanceProcessed', '-', ''),
                // ]),
                // new AppMenuItem('Leave Encashment', 'Pages.LeaveEncashment', '-', ''),
                // new AppMenuItem('Bonus', '', '-', '', [
                //     new AppMenuItem('Not Proceesed', 'Pages.BonusNotProcessed', '-', ''),
                //     new AppMenuItem('Proceesed', 'Pages.BonusProcessed', '-', ''),
                // ]),
                // new AppMenuItem('Reimbursement', '', '-', '', [
                //     new AppMenuItem('Not Proceesed', 'Pages.ReimbursementNotProcessed', '-', ''),
                //     new AppMenuItem('Proceesed', 'Pages.ReimbursementProcessed', '-', ''),
                //     new AppMenuItem('Freeze And Publish', 'Pages.ReimbursementFreezePublish', '-', ''),
                // ]),
                // new AppMenuItem('Investment Details', 'Pages.InvestmentDetails', '-', ''),
                // new AppMenuItem('Salary Processing', '', '-', '', [
                //     new AppMenuItem('Salary Details', 'Pages.Countries', '-', ''),
                //     new AppMenuItem('Salary Process', 'Pages.Salaries', '-', '/app/main/salary/salaryprocess'),
                //     new AppMenuItem('Freeze And Publish', 'Pages.Countries', '-', ''),
                //     new AppMenuItem('Salary Reconciliation', 'Pages.Countries', '-', ''),
                // ]),
                // new AppMenuItem('Payments', 'Pages.Payments', '-', '', [
                //     new AppMenuItem('Unprocessed Employees', 'Pages.UnprocessedEmployees', '-', ''),
                //     new AppMenuItem('Processed Employees', 'Pages.ProcessedEmployees', '-', ''),
                // ]),
                new AppMenuItem("Challan", "", "-", "", [
                    // new AppMenuItem('Tax', 'Pages.Tax', '-', ''),
                    new AppMenuItem(
                        "Provident Fund(PF)",
                        "Pages.ProvidentFunds",
                        "-",
                        "/app/main/providentFund/providentFunds"
                    ),
                    // new AppMenuItem('Employee State Insurance(ESI)', 'Pages.ESI', '-', ''),
                    // new AppMenuItem('Professional Tax (PT)', 'Pages.PT', '-', ''),
                    // new AppMenuItem('Form 16', 'Pages.Form', '-', ''),
                    new AppMenuItem(
                        "Monthly PF Challan",
                        "Pages.Reports.MonthlyPFChallan",
                        "-",
                        "/app/main/report/monthlypfchallan"
                    ),
                    new AppMenuItem(
                        "Monthly ESI Challan",
                        "Pages.Reports.MonthlyESIChallan",
                        "-",
                        "/app/main/report/monthlyesichallan"
                    ),
                ]),
                //new AppMenuItem('TDS Feeding', 'Pages.TDSFeeding', '-', '/app/main/TDSFeeding'),
                new AppMenuItem(
                    "Bank Payment Sheet",
                    "Pages.Reports.BankPaymentSheet",
                    "-",
                    "/app/main/report/bankpaymentreport"
                ),
                new AppMenuItem("Pay Policy", "", "-", "", [
                    new AppMenuItem("Statutory Configuration", "", "-", "", [
                        // new AppMenuItem('Statutory Setting', 'Pages.StatutorySetting', '-', ''),
                        new AppMenuItem(
                            "Provident Fund Setting",
                            "Pages.ProvidentFundSettings",
                            "-",
                            "/app/main/providentFundSetting/providentFundSettings"
                        ),
                        // new AppMenuItem('Provident Fund Comapnay Setting', 'Pages.ProvidentFunds', '-', '/app/main/providentFund/providentFunds'),
                        new AppMenuItem(
                            "Employee State Insurance Setting",
                            "Pages.EmployeeStateInsurance",
                            "-",
                            "/app/main/employeeStateInsurance/employeeStateInsurances"
                        ),
                        new AppMenuItem(
                            "Labour Welfare Fund And Professional Tax Setting",
                            "Pages.LabourWelfaresettings",
                            "-",
                            "/app/main/fund/labourWelfaresettings"
                        ),
                        // new AppMenuItem('Labour Welfare Fund Setting', 'Pages.Countries', '-', ''),
                        // new AppMenuItem('Bonus Setting', 'Pages.BonusSetting', '-', ''),
                    ]),
                    new AppMenuItem(
                        "Pay Component",
                        "Pages.PayComponetses",
                        "-",
                        "/app/main/payComponets/payComponetses"
                    ),
                    new AppMenuItem(
                        "Pay Grade",
                        "Pages.PayGrades",
                        "-",
                        "/app/main/payGrade/payGrades"
                    ),
                    new AppMenuItem(
                        "Salary Advance",
                        "Pages.SalaryAdvances",
                        "-",
                        "/app/main/salaryAdvance/salaryAdvances"
                    ),
                     //new AppMenuItem('Increment Policy', 'Pages.IncrementPolicy', '-', ''),
                ]),
            ]),
            // new AppMenuItem('ASSET', 'Pages.Asset', 'flaticon-home', '', [
            //     new AppMenuItem('Asset Type', 'Pages.AssetType', '-', '/app/main/asset-master'),
            //     new AppMenuItem('Asset', 'Pages.AssetMaster', '-', '/app/main/Asset'),
            //     new AppMenuItem('Assigned', 'Pages.Assigned', '-', '/app/main/AssignAsset'),
            // ]),

            new AppMenuItem(
                "RECRUITEMENT",
                "Pages.Recruiement",
                "flaticon-list",
                "",
                [
                    // new AppMenuItem('OPEN POSITION', '', 'flaticon-up-arrow', '', [
                    // new AppMenuItem('Approved', 'Pages.Countries', '-', ''),
                    // new AppMenuItem('Pending', 'Pages.Countries', '-', ''),
                    // new AppMenuItem('Rejected', 'Pages.Countries', '-', ''),
                    // ]),
                    new AppMenuItem(
                        "New Job Opening",
                        "Pages.NewJobOpening",
                        "-",
                        "/app/main/hrwork/jobOpenings"
                    ),
                    new AppMenuItem(
                        "Candidate",
                        "Pages.Candidate",
                        "-",
                        "/app/main/candidate/candidates"
                    ),
                    new AppMenuItem(
                        "Interview",
                        "Pages.CandidateinterviewDetailses",
                        "-",
                        "/app/main/candidate/candidateinterviewDetailses"
                    ),
                    // new AppMenuItem('MenuSearch', 'Pages.Countries', '-', '/app/main/MenuSearch'),
                    //     new AppMenuItem('Budget', 'Pages.Recruiement', '-', ''),
                    //     new AppMenuItem('Offers', 'Pages.Recruiement ', '-', ''),
                ]
            ),

            //    new AppMenuItem('Candidate', 'Pages.Candidates', '-', '/app/main/candidate/candidates'),
            //     new AppMenuItem('Interview', 'Pages.Countries', '-', ''),
            //     new AppMenuItem('Budget', 'Pages.Countries', '-', ''),
            //     new AppMenuItem('Offers', 'Pages.Countries', '-', ''),

            // new AppMenuItem('Dynamic Report', 'Pages.SelfService', '-', '/app/main/report/dynamicReport'),

            new AppMenuItem(
                "REPORTS",
                "Pages.Reports",
                "flaticon-line-graph",
                "",
                [
                    new AppMenuItem(
                        "Reports ",
                        "Pages.Reports.ReportSchemases",
                        "-",
                        "/app/main/reporttab/reporttab"
                    ),
                    new AppMenuItem(
                        "Report Group",
                        "Pages.Reports.ReportSchemases",
                        "-",
                        "/app/main/reportSchema/reportSchemases"
                    ),
                    // new AppMenuItem('Attendance Report', 'Pages.Reports.AttendanceReport', '-', '/app/main/report/attendancereport'),

                    //shikha-----------------------------------------------
                    // new AppMenuItem('Leave', 'Pages.Reports.Leave', '-', '', [
                    //     new AppMenuItem('Leave Summary', 'Pages.Reports.LeaveSummary', '-', '/app/main/report/leavesummary'),
                    //     new AppMenuItem('Leave Register', 'Pages.Reports.LeaveRegister', '-', '/app/main/report/leaveregisterreport'),
                    // ]),
                    // new AppMenuItem('PF Reports', 'Pages.Reports.PF', '-', '', [
                    //     new AppMenuItem('PF Statement', 'Pages.Reports.PFStatement', '-', '/app/main/report/pfstatementreport'),
                    //     new AppMenuItem('PF Chart Summary', 'Pages.Reports.PFChartSummary', '-', '/app/main/report/PfChartsummary'),
                    //     new AppMenuItem('PF ECR Report', 'Pages.Reports.PFECRReport', '-', '/app/main/report/pfecrreport'),
                    //     new AppMenuItem('PF DCR Upload Format', 'Pages.Reports.PFDCRUploadFormat', '-', '/app/main/report/pfdcruploadformat'),
                    // ]),

                    // new AppMenuItem('ESI Reports', 'Pages.Reports.ESIStatement', '-', '', [
                    //     new AppMenuItem('ESI Statement', 'Pages.Reports.ESIStatement', '-', '/app/main/report/esistatementreport'),
                    //     new AppMenuItem('ESI Chart Summary', 'Pages.Reports.ESIChartSummary', '-', '/app/main/report/EsiChartsummary'),
                    //     new AppMenuItem('ESI Form 7', 'Pages.Reports.ESIFORM7', '-', '/app/main/report/EsiForm7'),

                    // ]),
                    // new AppMenuItem('Salary Summary', 'Pages.Reports.EmployeeSalaryRegister', '-', '/app/main/salarysummary/salarysummary'),

                    // new AppMenuItem('Salary Register CTC', 'Pages.Reports.SalaryRegisterCTC', '-', '/app/main/report/salarystatement'),
                    // new AppMenuItem('Salary Register', 'Pages.Reports.EmployeeSalaryRegister', '-', '/app/main/report/employeeregister'),
                    // new AppMenuItem('Salary Structure', 'Pages.Reports.SalaryRegisterPDF', '-', '/app/main/report/SalaryRegisterBasic'),

                    //shikha------------------------------------
                    // new AppMenuItem('Asset', 'Pages.Reports.AssetReport', '-', '/app/main/report/assetReport'),
                    // new AppMenuItem('Form 12A', 'Pages.Reports.FORM12A', '-', '/app/main/report/form12'),
                    // new AppMenuItem('LWF Contribution', 'Pages.Reports.LWFContribution', '-', '/app/main/report/welfarecontribution'),

                    // new AppMenuItem('Contractor Bill', 'Pages.ContractorBill', '-', '/app/main/report/ContractorBill'),
                    // new AppMenuItem('Bank Payment Sheet', 'Pages.Reports.BankPaymentSheet', '-', '/app/main/report/bankpaymentreport'),
                    //  new AppMenuItem('Full Calendar', 'Pages.Reports.ExistJoining', '-', '/app/main/calendar/fullcalendar'),
                    //Divya--------------------------------------------------------------//
                    new AppMenuItem(
                        "Listing",
                        "Pages.Listing",
                        "flaticon-list-3",
                        "",
                        [
                            new AppMenuItem(
                                "Employees Listing",
                                "Pages.Listing.Employee",
                                "-",
                                "/app/main/Listing/EmployeesListing"
                            ),
                            new AppMenuItem(
                                "Salary Structure",
                                "Pages.Listing.SalaryStructure",
                                "-",
                                "/app/main/report/SalaryRegisterBasic"
                            ),
                        ]
                    ),
                ]
            ),
            //Divya----------------------------------//
            new AppMenuItem(
                "Full Calendar",
                "Pages.Reports.ExistJoining",
                "-",
                "/app/main/calendar/fullcalendar"
            ),

            // new AppMenuItem('Request For Approval', 'Pages.RequestForApprovals', 'flaticon-up-arrow', '/app/main/requestForApproval/requestForApprovals'),

            //new AppMenuItem('Mark Attendance', 'Pages.AttendanceRules', 'flaticon-up-arrow', '/app/main/attendance/markattendance'),

            //new AppMenuItem('PayGradeDetailses', 'Pages.PayGradeDetailses', 'flaticon-more', '/app/main/payGradeDetails/payGradeDetailses'),

            //new AppMenuItem('AdvanceTypes', 'Pages.AdvanceTypes', 'flaticon-more', '/app/main/advanceType/advanceTypes'),

            //new AppMenuItem('PayGradeDetailses', 'Pages.PayGradeDetailses', 'flaticon-more', '/app/main/payGradeDetails/payGradeDetailses'),

            //new AppMenuItem('EmployeeSalaryComponentses', 'Pages.EmployeeSalaryComponentses', 'flaticon-more', '/app/main/employeeSalary/employeeSalaryComponentses'),

            // new AppMenuItem('BankBranchs', 'Pages.BankBranchs', 'flaticon-more', '/app/main/bankBranch/bankBranchs'),

            //new AppMenuItem('BankBranchDetailses', 'Pages.BankBranchDetailses', 'flaticon-more', '/app/main/bankBranch/bankBranchDetailses'),

            //  new AppMenuItem('Employeebankdetailses', 'Pages.Employeebankdetailses', 'flaticon-more', '/app/main/employee/employeebankdetailses'),

            // new AppMenuItem('ShiftDetailses', 'Pages.ShiftDetailses', 'flaticon-more', '/app/main/shift/shiftDetailses'),

            // new AppMenuItem('Salaries', 'Pages.Salaries', 'flaticon-more', '/app/main/salary/salaries'),

            // new AppMenuItem('SalaryDetialses', 'Pages.SalaryDetialses', 'flaticon-more', '/app/main/salary/salaryDetialses'),

            // new AppMenuItem('Professionals', 'Pages.Professionals', 'flaticon-more', '/app/main/fund/professionals'),

            // new AppMenuItem('StatutoryConfigurations', 'Pages.StatutoryConfigurations', 'flaticon-more', '/app/main/fund/statutoryConfigurations'),

            //     new AppMenuItem('LeaveBlanceses', 'Pages.LeaveBlanceses', 'flaticon-more', '/app/main/leaveRule/leaveBlanceses'),

            //   new AppMenuItem('ProvidentFunds', 'Pages.ProvidentFunds', 'flaticon-more', '/app/main/providentFund/providentFunds'),

            // new AppMenuItem('TenancyConfiguratonWizards', 'Pages.TenancyConfiguratonWizards', 'flaticon-more', '/app/main/installationwizards/tenancyConfiguratonWizards'),

            //     new AppMenuItem('JObRoundDetailses', 'Pages.JObRoundDetailses', 'flaticon-more', '/app/main/jobRoundDetails/jObRoundDetailses'),

            //  new AppMenuItem('SubLocations', 'Pages.SubLocations', 'flaticon-more', '/app/main/location/subLocations'),

            // new AppMenuItem('CandidateinterviewDetailses', 'Pages.CandidateinterviewDetailses', 'flaticon-more', '/app/main/candidate/candidateinterviewDetailses'),

            //new AppMenuItem('CandidateDocumnetses', 'Pages.CandidateDocumnetses', 'flaticon-more', '/app/main/candidate/candidateDocumnetses'),

            //
            // new AppMenuItem('Administration', '', 'flaticon-profile', '', [
            //     new AppMenuItem('Letter Templates', 'Pages.OffersTempletes', '-', '/app/main/offersTemplete/offersTempletes'),
            //     new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', '-', '/app/admin/organization-units'),
            //     new AppMenuItem('Roles', 'Pages.Administration.Roles', '-', '/app/admin/roles'),
            //     new AppMenuItem('Users', 'Pages.Administration.Users', '-', '/app/admin/users'),
            //     new AppMenuItem('Languages', 'Pages.Administration.Languages', '-', '/app/admin/languages'),
            //    // new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', '-', '/app/admin/auditLogs'),
            //     new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', '-', '/app/admin/maintenance'),
            //     new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', '-', '/app/admin/subscription-management'),
            //     // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', '-', '/app/admin/ui-customization'),
            //     new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', '-', '/app/admin/hostSettings'),
            //     new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', '-', '/app/admin/tenantSettings'),
            // ]),
            new AppMenuItem(
                "SETUP",
                "Pages.SettingMaster",
                "flaticon-user-settings",
                "",
                [
                    new AppMenuItem("Broadcast", "", "-", "", [
                        new AppMenuItem(
                            "Announcement",
                            "Pages.Announcements",
                            "-",
                            "/app/main/hrwork/announcements"
                        ),
                        new AppMenuItem(
                            "Quick Links",
                            "Pages.QuikLinkses",
                            "-",
                            "/app/main/quikLinks/quikLinkses"
                        ),
                        new AppMenuItem(
                            "Policy Document",
                            "Pages.Policieses",
                            "-",
                            "/app/main/hrwork/policieses"
                        ),
                        new AppMenuItem(
                            "Wishes Setting",
                            "Pages.BirthdayWishesSetting",
                            "-",
                            "/app/main/birthdayWishesSetting/birthdayWishesSetting"
                        ),
                        new AppMenuItem(
                            "New Hires Setting",
                            "Pages.BirthdayWishesSetting",
                            "-",
                            "/app/main/newhiresSetting/newhiresSetting"
                        ),
                    ]),

                    new AppMenuItem(
                        "Perfetto Setup",
                        "Pages.TenancyConfiguratonWizards",
                        "-",
                        "/app/main/installationwizards/tenancyConfiguratonWizards"
                    ),
                    new AppMenuItem(
                        "Education",
                        "Pages.Education",
                        "-",
                        "/app/main/education"
                    ),
                    new AppMenuItem(
                        "Country",
                        "Pages.Countries",
                        "-",
                        "/app/main/country/countries"
                    ),
                    new AppMenuItem(
                        "State",
                        "Pages.States",
                        "-",
                        "/app/main/state/states"
                    ),
                    new AppMenuItem(
                        "City",
                        "Pages.Cities",
                        "-",
                        "/app/main/city/cities"
                    ),

                    new AppMenuItem(
                        "On Duty Type",
                        "Pages.OnDuty",
                        "-",
                        "/app/main/OnDutyType/OnDutyType"
                    ),

                    //new AppMenuItem('Mail Setting', 'Pages.MailSetting', '-', ''),

                    // new AppMenuItem('Mail Setting', 'Pages.MailSetting', '-', ''),
                    // new AppMenuItem('Sms Setting', 'Pages.SmsSetting', '-', ''),

                    new AppMenuItem(
                        "Work Flows",
                        "Pages.WorkFlow",
                        "-",
                        "/app/main/workFlow/requesrFlows"
                    ),
                    // new AppMenuItem('Mail Template', 'Pages.MailTemplate', '-', ''),
                    // new AppMenuItem('Alert', 'Pages.Alert', '-', ''),
                    // new AppMenuItem('Dashboard', '', '-', '', [
                    //     //new AppMenuItem('Announcement', 'Pages.Announcement', '-', ''),
                    //     new AppMenuItem('Downloads', 'Pages.Downloads', '-', ''),
                    //     new AppMenuItem('Wishes', 'Pages.Wishes', '-', ''),
                    //     new AppMenuItem('Policy', 'Pages.Policies', '-', ''),

                    //     new AppMenuItem('Attendance', 'Pages.Attendance', '-', ''),
                    // ]),
                    // new AppMenuItem('Checklist', 'Pages.Checklist', '-', ''),
                    // new AppMenuItem('Letter Template', 'Pages.LetterTemplate', '-', ''),
                    new AppMenuItem(
                        "Document Type",
                        "Pages.DocumentTypes",
                        "-",
                        "/app/main/documentType/documentTypes"
                    ),
                    new AppMenuItem(
                        "Contractors",
                        "Pages.Contrators",
                        "-",
                        "/app/main/contrator/contrators"
                    ),
                    new AppMenuItem(
                        "Employee Code Settings",
                        "Pages.EmplolyeeCodeSettings",
                        "-",
                        "/app/main/emplolyeeCodeSetting/emplolyeeCodeSettings"
                    ),
                    new AppMenuItem(
                        "Letter Templates",
                        "Pages.OffersTempletes",
                        "-",
                        "/app/main/offersTemplete/offersTempletes"
                    ),
                    new AppMenuItem(
                        "OrganizationUnits",
                        "Pages.Administration.OrganizationUnits",
                        "-",
                        "/app/admin/organization-units"
                    ),
                    new AppMenuItem(
                        "Roles",
                        "Pages.Administration.Roles",
                        "-",
                        "/app/admin/roles"
                    ),
                    new AppMenuItem(
                        "Users",
                        "Pages.Administration.Users",
                        "-",
                        "/app/admin/users"
                    ),
                    new AppMenuItem(
                        "Languages",
                        "Pages.Administration.Languages",
                        "-",
                        "/app/admin/languages"
                    ),
                    // new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', '-', '/app/admin/auditLogs'),
                    new AppMenuItem(
                        "Maintenance",
                        "Pages.Administration.Host.Maintenance",
                        "-",
                        "/app/admin/maintenance"
                    ),
                    new AppMenuItem(
                        "Subscription",
                        "Pages.Administration.Tenant.SubscriptionManagement",
                        "-",
                        "/app/admin/subscription-management"
                    ),
                    // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', '-', '/app/admin/ui-customization'),
                    new AppMenuItem(
                        "Settings",
                        "Pages.Administration.Host.Settings",
                        "-",
                        "/app/admin/hostSettings"
                    ),
                    new AppMenuItem(
                        "Settings",
                        "Pages.Administration.Tenant.Settings",
                        "-",
                        "/app/admin/tenantSettings"
                    ),
                    //new AppMenuItem('Organization', '', '-', '', [
                    // new AppMenuItem('Company', '', '-', '', [

                    //     new AppMenuItem('Department', 'Pages.Departmentses', '-', '/app/main/depatrment/departmentses'),
                    //     new AppMenuItem('Sub Departments', 'Pages.SubDepartments', '-', '/app/main/departMent/subDepartments'),

                    //     new AppMenuItem('Designation', 'Pages.Designations', '-', '/app/main/designation/designations'),
                    //     new AppMenuItem('Division', 'Pages.Divisions', '-', '/app/main/division/divisions'),
                    //     new AppMenuItem('Document Type', 'Pages.DocumentTypes', '-', '/app/main/documentType/documentTypes'),
                    //     new AppMenuItem('Contrators', 'Pages.Contrators', '-', '/app/main/contrator/contrators'),
                    //     new AppMenuItem('EmplolyeeCode Settings', 'Pages.EmplolyeeCodeSettings', '-', '/app/main/emplolyeeCodeSetting/emplolyeeCodeSettings'),

                    // ]),

                    // new AppMenuItem('Location', '', '-', '', [
                    //     new AppMenuItem('Location', 'Pages.Locationses', '-', '/app/main/location/locationses'),
                    //     new AppMenuItem('Sub Locations', 'Pages.SubLocations', '-', '/app/main/location/subLocations'),
                    //     new AppMenuItem('Country', 'Pages.Countries', '-', '/app/main/country/countries'),
                    //     new AppMenuItem('State', 'Pages.States', '-', '/app/main/state/states'),
                    //     new AppMenuItem('City', 'Pages.Cities', '-', '/app/main/city/cities'),
                    // ]),
                    // new AppMenuItem('Education', 'Pages.Education', '-', '/app/main/education'),
                    // new AppMenuItem('NATIONALITY', 'Pages.Nationalities', 'flaticon-up-arrow', '/app/main/nationality/nationalities'),
                    //]),
                    //new AppMenuItem('General', 'Pages.General', '-', '', [
                    // new AppMenuItem('Setting', '', '-', '', [
                    //     new AppMenuItem('Mail Setting', 'Pages.MailSetting', '-', ''),
                    //     new AppMenuItem('Sms Setting', 'Pages.SmsSetting', '-', ''),
                    // ]),
                    // new AppMenuItem('Work Flows', 'Pages.WorkFlow', '-', '/app/main/workFlow/requesrFlows'),
                    // new AppMenuItem('Mail Template', 'Pages.MailTemplate', '-', ''),
                    // new AppMenuItem('Alert', 'Pages.Alert', '-', ''),
                    //     new AppMenuItem('Dashboard', '', '-', '', [
                    //         new AppMenuItem('Announcement', 'Pages.Announcement', '-', ''),
                    //         new AppMenuItem('Downloads', 'Pages.Downloads', '-', ''),
                    //         new AppMenuItem('Wishes', 'Pages.Wishes', '-', ''),
                    //         new AppMenuItem('Policy', 'Pages.Policies', '-', ''),
                    //         new AppMenuItem('Recruitment', 'Pages.Recruitment', '-', ''),
                    //         new AppMenuItem('Leave', 'Pages.Leave', '-', ''),
                    //         new AppMenuItem('Attendance', 'Pages.Attendance', '-', ''),
                    //     ]),
                    //     new AppMenuItem('Checklist', 'Pages.Checklist', '-', ''),
                    //     new AppMenuItem('Letter Template', 'Pages.LetterTemplate', '-', ''),
                    // ]),
                    // new AppMenuItem('Employee Policy', '', '-', '', [
                    //     new AppMenuItem('Confirmation', '', '-', '', [
                    //         new AppMenuItem('Policy', 'Pages.EmployePolicy', '-', ''),
                    //     ]),
                    //     new AppMenuItem('Separation', '', '-', '', [
                    //         new AppMenuItem('Exit Questionnaire', 'Pages.ExitQuestionnaire', '-', ''),
                    //         new AppMenuItem('Clearance Check List', 'Pages.ClearanceCheckList', '-', ''),
                    //     ]),
                    //     new AppMenuItem('Setting', 'Pages.Settings', '-', ''),
                    //     new AppMenuItem('Document', 'Pages.Document', '-', ''),
                    // ]),
                    // new AppMenuItem('Time Office Policy', '', '-', '', [
                    //     new AppMenuItem('Attendance', '', '-', '', [
                    //         new AppMenuItem('Create Shift', 'Pages.Shifts', '-', '/app/main/shift/shifts'),
                    //         new AppMenuItem('Attendance Rule', 'Pages.AttendanceRules', '-', '/app/main/attendanceRule/attendanceRules'),
                    //     ]),
                    //     new AppMenuItem('Leave', '', '-', '', [
                    //         new AppMenuItem('Leave Type', 'Pages.LeaveTypes', '-', '/app/main/leaveTypes/leaveTypes'),
                    //         new AppMenuItem('Leave Rule', 'Pages.LeaveRules', '-', '/app/main/leaveRule/leaveRules'),
                    //     ]),
                    //     new AppMenuItem('Holiday', 'Pages.HoldiayPolicies', '-', '/app/main/holidayPolicy/holdiayPolicies'),
                    //     new AppMenuItem('Holiday Names', 'Pages.Holidaies', '-', '/app/main/holiday/holidaies'),
                    //     //new AppMenuItem('Check In', 'Pages.Countries', '-', ''),
                    //     // new AppMenuItem('SETTING', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    //     //new AppMenuItem('On Duty', 'Pages.Countries', '-', ''),
                    // ]),
                    // new AppMenuItem('Pay Policy', '', '-', '', [
                    //     new AppMenuItem('Statutory Configuration', '', '-', '', [
                    //         new AppMenuItem('Statutory Setting', 'Pages.StatutorySetting', '-', ''),
                    //         new AppMenuItem('Provident Fund Setting', 'Pages.ProvidentFundSettings', '-', '/app/main/providentFundSetting/providentFundSettings'),
                    //         // new AppMenuItem('Provident Fund Comapnay Setting', 'Pages.ProvidentFunds', '-', '/app/main/providentFund/providentFunds'),
                    //         new AppMenuItem('Employee State Insurance Setting', 'Pages.EmployeeStateInsurance', '-','/app/main/employeeStateInsurance/employeeStateInsurances'),
                    //         new AppMenuItem('Labour Welfare Fund And Professional Tax Setting', 'Pages.LabourWelfaresettings', '-', '/app/main/fund/labourWelfaresettings'),
                    //        // new AppMenuItem('Labour Welfare Fund Setting', 'Pages.Countries', '-', ''),
                    //         new AppMenuItem('Bonus Setting', 'Pages.BonusSetting', '-', ''),
                    //     ]),
                    //     new AppMenuItem('Pay Component', 'Pages.PayComponetses', '-', '/app/main/payComponets/payComponetses'),
                    //     new AppMenuItem('Pay Grade', 'Pages.PayGrades', '-', '/app/main/payGrade/payGrades'),
                    //     new AppMenuItem('Salary Advance', 'Pages.SalaryAdvances', '-', '/app/main/salaryAdvance/salaryAdvances'),
                    //     new AppMenuItem('Increment Policy', 'Pages.IncrementPolicy', '-', ''),
                    // ]),
                    // new AppMenuItem('Recruitement', '', '-', '', [
                    //     new AppMenuItem('Position', '', '-', '', [
                    //         new AppMenuItem('Job Description', 'Pages.Countries', '-', ''),
                    //         new AppMenuItem('Hold Reason', 'Pages.Countries', '-', ''),
                    //     ]),
                    // ]),
                    // new AppMenuItem('EXPENSE', '', 'flaticon-up-arrow', '', [
                    //     new AppMenuItem('LOCAL CONVEYANCE', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    //     new AppMenuItem('OTHER EXPENSE', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    //     new AppMenuItem('SETTING', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    // ]),
                    // new AppMenuItem('TRAVEL', '', 'flaticon-up-arrow', '', [
                    //     new AppMenuItem('POLICY', '', 'flaticon-up-arrow', '', [
                    //         new AppMenuItem('ALLOWANCE TYPE', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    //         new AppMenuItem('ADVANCE POLICY', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    //         new AppMenuItem('GROUP LOCATION', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    //         new AppMenuItem('POLICY', 'Pages.Countries', 'flaticon-up-arrow', ''),
                    //     ]),
                    // ]),
                    //]),
                    // new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
                    // new AppMenuItem('Listing', 'Pages.Listing', 'flaticon-app', '',
                    // [
                    //     new AppMenuItem('EmployeesListing', 'Pages.Listing', '-', '/app/main/Listing/EmployeesListing'),
                    //     new AppMenuItem('Salary Structure', 'Pages.Reports.SalaryRegisterPDF', '-', '/app/main/report/SalaryRegisterBasic'),
                    // ]),
                ]
            ),
        ]);
    }

    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
          allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });
    
        return allMenuItems;
      }
      
  private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
    if (!menuItem.items) {
      return [menuItem];
    }

    let menuItems = [menuItem];
    menuItem.items.forEach((subMenu) => {
      menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
    });

    return menuItems;
  }
    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (
                subMenuItem.permissionName &&
                this._permissionCheckerService.isGranted(
                    subMenuItem.permissionName
                )
            ) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName ===
                "Pages.Administration.Tenant.SubscriptionManagement" &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (
            menuItem.permissionName &&
            !this._permissionCheckerService.isGranted(menuItem.permissionName)
        ) {
            hideMenuItem = true;
        }

        if (
            this._appSessionService.tenant ||
            !abp.multiTenancy.ignoreFeatureCheckForHostUsers
        ) {
            if (
                menuItem.hasFeatureDependency() &&
                !menuItem.featureDependencySatisfied()
            ) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
